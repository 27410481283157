import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IPortalConfig, PORTAL_DATA, PortalApiType} from '@jumio/portals.core';
import * as _ from 'lodash-es';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {CacheService} from 'shared/services/common/cache.service';
import {Documents} from 'shared/services/documents/documents.dto';
import {MerchantEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {MultiDocument} from 'shared/services/merchant/multi-document.dto';
import {SearchService} from 'shared/services/search.service';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
import {AcceptedIdsCountryRequest, MerchantAcceptedIdsCountry} from './accepted-ids-country.dto';
import {MerchantAcceptedIdsRegion} from './accepted-ids-region.dto';
import {ApplicationSettingsGeneral, ApplicationSettingsGeneralInit} from './application-settings-general.dto';
import {ApplicationSettingsRedirect, ApplicationSettingsRedirectInit} from './application-settings-redirect.dto';
import {CustomizeClientGeneral, CustomizeClientGeneralInit} from './customize-client-general.dto';
import {CustomizeClientRedirect, CustomizeClientRedirectInit, LocaleWithImageUrlList} from './customize-client-redirect.dto';
import {MerchantFilter, MerchantFilterInit} from './merchant-filter.dto';
import {
  GenericValue,
  JumioPlatformUpgradeSettings,
  MerchantIpRanges,
  MerchantOfflineTokenSettings,
  MerchantSettings,
  MerchantSettingsRequest,
  OfflineTokenResult
} from './merchant-settings.dto';
import {AccountDuplicationPayload, Merchant, MerchantDetail, MerchantTypes} from './merchant.dto';
import {MultiDocumentDetails} from './multi-document-details.dto';
import {MultiDocumentsResult} from './multi-documents-result.dto';
import {SlaFraudSettings, SlaFraudSettingsFields} from './sla-fraud-settings.dto';

/**
 * The service responsible for sending and receiving Merchant data between the client and server.
 */
@Injectable()
export class MerchantService extends BaseService implements SearchService, FilterService {
  private MERCHANT_FILTER_INIT_CACHE_KEY = 'MERCHANT_FILTER_INIT';
  private APP_SETTINGS_GENERAL_INIT_CACHE_KEY = 'APP_SETTINGS_GENERAL_INIT';
  private APP_SETTINGS_REDIRECT_INIT_CACHE_KEY = 'APP_SETTINGS_REDIRECT_INIT';
  private SLA_FRAUD_SETTINGS_FIELDS_CACHE_KEY = 'SLA_FRAUD_SETTINGS_FIELDS';

  public defaultRegionConfig: MerchantAcceptedIdsRegion | undefined | null;
  // service is used on UP and AP
  public isAdminPortal: boolean | undefined;

  constructor(
    protected contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected cache: CacheService,
    @Inject(PORTAL_DATA) protected portalConfig: IPortalConfig
  ) {
    super(http);
    this.isAdminPortal = this.portalConfig.apiType === PortalApiType.AP;
    this.baseUrl = this.isAdminPortal ? MerchantEndpoints.BASE : BaseEndpoints.SETTINGS;
    this.prefix = this.isAdminPortal ? MerchantEndpoints.AUI_PREFIX : MerchantEndpoints.MUI_PREFIX;
  }

  /**
   * Adds an appropriate prefix for the endpoint URL depending on the given app (MUI or AUI).
   */
  public prefix: any = () => {};

  /**
   * Sends a new merchant registration request.
   * @param merchant MerchantDetail.
   * @param password The password of the current user.
   * @returns {Observable<void>} Empty if the request was successful, error if it wasn't.
   */
  public addMerchant$(merchant: MerchantDetail, password: string): Observable<void> {
    return this.post$<void>(MerchantEndpoints.MERCHANT, new EntityWithPassword(merchant, password));
  }

  /**
   * Modify merchant details.
   * @param merchant the new object representing a Merchant.
   * @param password The password of the current user.
   * @param withEnabled Determines whether the "enabled" field should be included in the request or not.
   * @returns {Observable<void>}
   */
  public modifyDetail$(merchant: Merchant, password: string, withEnabled = true): Observable<void> {
    //@ts-ignore
    return this.put$<void>(merchant.publicId, new EntityWithPassword(Merchant.toRequestDto(merchant, withEnabled), password));
  }

  /**
   * Initialize the merchant filter.
   * @returns {Observable<MerchantFilterInit>} The result in an Observable.
   */
  public initMerchantFilter$(): Observable<MerchantFilterInit> {
    const fn$ = this.get$<MerchantFilterInit>(MerchantEndpoints.INIT);
    return this.cache.cacheFunction$<MerchantFilterInit>(this.MERCHANT_FILTER_INIT_CACHE_KEY, fn$);
  }

  /**
   * Filter merchants.
   * @param filter The filter object.
   * @returns {Observable<JumioPage<Merchant>>} Returns the merchant page by filter.
   */
  public filter$(filter: MerchantFilter): Observable<JumioPage<Merchant>> {
    filter.productType = this.contextHolder.productType;
    return this.post$<JumioPage<Merchant>>(MerchantEndpoints.FILTER, filter).pipe(
      tap(result => {
        for (const item of result.list ?? []) {
          if (item.createdAt) {
            item.createdAt = new Date(item.createdAt);
          }
        }
        return result;
      })
    );
  }

  /**
   * Sends a search query to the backend for receiving a list of merchants.
   * @param query The query string we send towards the backend,
   * @returns {Observable<Array<Merchant>>} An Observable of merchants that met the search query.
   */
  public search$(query: string): Observable<Array<Merchant>> {
    return this.post$<Array<Merchant>>(MerchantEndpoints.SEARCH, new MerchantSearchQuery(query));
  }

  /**
   * Returns the redirect application settings for a given merchant.
   * @returns {Observable<ApplicationSettingsGeneral>} The result in an Observable.
   */
  public getMerchantTypes$(): Observable<MerchantTypes> {
    return this.get$<MerchantTypes>(MerchantEndpoints.TYPES);
  }

  /**
   * Returns Merchant by publicId
   * @param id The public id
   * @returns {Observable<Merchant>} Returns the selected merchant by id.
   */
  public getDetail$(id: string): Observable<Merchant> {
    return this.get$<Merchant>(this.prefix(id)).pipe(
      tap(merchant => {
        //@ts-ignore
        merchant.createdAt = new Date(merchant.createdAt);
        return merchant;
      })
    );
  }

  /**
   * Returns the Accepted IDs table by countries.
   * @param {string} id The id of the given merchant.
   * @returns {Observable<MerchantAcceptedIdsCountry>} The result in an Observable.
   */
  public getAcceptedIdsCountry$(id: string): Observable<MerchantAcceptedIdsCountry> {
    return this.get$<MerchantAcceptedIdsCountry>(this.prefix(id) + MerchantEndpoints.ACCEPTED_IDS_COUNTRY);
  }

  /**
   * Sends a modification request for the merchant's accepted Ids country settings.
   * @param {string} id The merchant's ID.
   * @param {MerchantAcceptedIdsCountry} acceptedIds The new object representing the accepted IDs settings.
   * @param {string} password The current user's password.
   * @param {string} passCode The current user's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyAcceptedIdsCountry$(
    id: string,
    acceptedIds: MerchantAcceptedIdsCountry,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.ACCEPTED_IDS_COUNTRY,
      new EntityWithPassword(new AcceptedIdsCountryRequest(acceptedIds), password, passCode)
    );
  }

  /**
   * Downloads a CSV file with the current Accepted IDs for Countries
   */
  public downloadAcceptedIdsCountry$(): Observable<Blob> {
    return this.getCsv$(MerchantEndpoints.ACCEPTED_IDS_COUNTRY_DOWNLOAD);
  }

  /**
   * Returns the Accepted IDs table by region.
   * @param {string} id The id of the given merchant.
   * @returns {Observable<MerchantAcceptedIdsRegion>} The result in an Observable.
   */
  public getAcceptedIdsRegion$(id: string): Observable<MerchantAcceptedIdsRegion> {
    return this.get$<MerchantAcceptedIdsRegion>(this.prefix(id) + MerchantEndpoints.ACCEPTED_IDS_REGION);
  }

  /**
   * Sends a modification request for the merchant's accepted Ids region settings.
   * @param {string} id The merchant's ID.
   * @param {MerchantAcceptedIdsRegion} acceptedIds The new object representing the accepted IDs settings.
   * @param {string} password The current user's password.
   * @param {string} passCode The current user's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyAcceptedIdsRegion$(
    id: string,
    acceptedIds: MerchantAcceptedIdsRegion,
    password: string,
    passCode?: string
  ): Observable<void> {
    acceptedIds.active = true;

    const body = _.cloneDeep(acceptedIds);
    if (acceptedIds.regions) {
      // Countries don't need to be re-sent, since they can't be changed
      // Only delete it from the clone for submitting but not from the original entity
      body.regions?.forEach(region => delete region.countries);
    }
    return this.put$<void>(this.prefix(id) + MerchantEndpoints.ACCEPTED_IDS_REGION, new EntityWithPassword(body, password, passCode));
  }

  /**
   * Initialize the application settings general page.
   * @returns {Observable<ApplicationSettingsGeneralInit>} The result in an Observable.
   */
  public initApplicationSettingsGeneral$(): Observable<ApplicationSettingsGeneralInit> {
    const fn$ = this.get$<ApplicationSettingsGeneralInit>(MerchantEndpoints.APPLICATION_SETTINGS_GENERAL_INIT);
    return this.cache.cacheFunction$<ApplicationSettingsGeneralInit>(this.APP_SETTINGS_GENERAL_INIT_CACHE_KEY, fn$);
  }

  /**
   * Returns the general application settings for a given merchant.
   * @param id The ID of the merchant.
   * @returns {Observable<ApplicationSettingsGeneral>} The result in an Observable.
   */
  public getApplicationSettingsGeneral$(id: string): Observable<ApplicationSettingsGeneral> {
    return this.get$<ApplicationSettingsGeneral>(this.prefix(id) + MerchantEndpoints.APPLICATION_SETTINGS_GENERAL);
  }

  /**
   * Sends a modification request for the general application settings of a given merchant.
   * @param id The ID of the merchant.
   * @param settings The general application settings.
   * @param password The password of the current user.
   * @param passCode The security code of the current user.
   * @returns {Observable<void>} Empty if the request was successful, error if it wasn't.
   */
  public modifyApplicationSettingsGeneral$(
    id: string,
    settings: ApplicationSettingsGeneral,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.APPLICATION_SETTINGS_GENERAL,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the init state of the application settings redirect page.
   * @returns {Observable<ApplicationSettingsRedirectInit>} The result in an Observable.
   */
  public initApplicationSettingsRedirect$(): Observable<ApplicationSettingsRedirectInit> {
    const fn$ = this.get$<ApplicationSettingsRedirectInit>(MerchantEndpoints.APPLICATION_SETTINGS_REDIRECT_INIT);
    return this.cache.cacheFunction$<ApplicationSettingsRedirectInit>(this.APP_SETTINGS_REDIRECT_INIT_CACHE_KEY, fn$);
  }

  /**
   * Returns the redirect application settings for a given merchant.
   * @param id The ID of the merchant.
   * @returns {Observable<ApplicationSettingsGeneral>} The result in an Observable.
   */
  public getApplicationSettingsRedirect$(id: string): Observable<ApplicationSettingsRedirect> {
    return this.get$<ApplicationSettingsRedirect>(this.prefix(id) + MerchantEndpoints.APPLICATION_SETTINGS_REDIRECT);
  }

  /**
   * Sends a modification request for the redirect application settings of a given merchant.
   * @param id The ID of the merchant.
   * @param settings The redirect application settings.
   * @param password The password of the current user.
   * @param passCode The security code of the current user.
   * @returns {Observable<void>} Empty if the request was successful, error otherwise.
   */
  public modifyApplicationSettingsRedirect$(
    id: string,
    settings: ApplicationSettingsRedirect,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.APPLICATION_SETTINGS_REDIRECT,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Initializes the customize client general page.
   * @returns {Observable<CustomizeClientGeneralInit>} The result in an Observable.
   */
  public initCustomizeClientGeneral$(): Observable<CustomizeClientGeneralInit> {
    return this.get$<CustomizeClientGeneralInit>(MerchantEndpoints.CUSTOMIZE_CLIENT_GENERAL_INIT);
  }

  /**
   * Returns the init state of the customize client redirect page.
   *
   * @param {string} id The id of the current merchant.
   * @returns {Observable<CustomizeClientRedirectInit>} The result in an Observable.
   */
  public initCustomizeClientRedirect$(): Observable<CustomizeClientRedirectInit> {
    return this.get$<CustomizeClientRedirectInit>(MerchantEndpoints.CUSTOMIZE_CLIENT_REDIRECT_INIT);
  }

  /**
   * Returns the client customization general settings for a given merchant.
   * @param id The ID of the merchant.
   * @returns {Observable<CustomizeClientGeneral>} The result in an Observable.
   */
  public getCustomizeClientGeneral$(id: string): Observable<CustomizeClientGeneral> {
    return this.get$<CustomizeClientGeneral>(this.prefix(id) + MerchantEndpoints.CUSTOMIZE_CLIENT_GENERAL);
  }

  /**
   * Sends a modification request for the client customization settings of a given merchant.
   * @param id The ID of the merchant.
   * @param settings The client customization settings.
   * @param password The password of the current user.
   * @param passCode The security code of the current user.
   * @returns {Observable<void>} Empty if the request was successful, error if it wasn't.
   */
  public modifyCustomizeClientGeneral$(id: string, settings: CustomizeClientGeneral, password: string, passCode: string): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.CUSTOMIZE_CLIENT_GENERAL,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the client customization redirect settings for a given merchant.
   * @param id The ID of the merchant.
   * @returns {Observable<CustomizeClientRedirect>} The result in an Observable.
   */
  public getCustomizeClientRedirect$(id: string): Observable<CustomizeClientRedirect> {
    return this.get$<CustomizeClientRedirect>(this.prefix(id) + MerchantEndpoints.CUSTOMIZE_CLIENT_REDIRECT);
  }

  /**
   * Sends a modification request for the client customization redirect settings of a given merchant.
   * @param id The ID of the merchant.
   * @param formData The FormData
   * @returns {Observable<LocaleWithImageUrlList>}
   */
  public modifyCustomizeClientRedirect$(id: string, formData: FormData): Observable<LocaleWithImageUrlList> {
    return this.put$<LocaleWithImageUrlList>(this.prefix(id) + MerchantEndpoints.CUSTOMIZE_CLIENT_REDIRECT, formData);
  }

  /**
   * Returns a given merchant's settings.
   * @param {string} id The id of the given merchant.
   * @returns {Observable<MerchantSettings>} The result in an Observable.
   */
  public getSettings$(id: string): Observable<MerchantSettings> {
    return this.get$<MerchantSettings>(MerchantEndpoints.SETTINGS_TYPE(id, this.contextHolder.productType.toLowerCase())).pipe(
      tap(settings => {
        if (settings.offlineTokenSettings && settings.offlineTokenSettings.expirationDate) {
          settings.offlineTokenSettings.expirationDate = new Date(settings.offlineTokenSettings.expirationDate);
        }
      })
    );
  }

  /**
   * Sends a modification request for the merchant's settings.
   * Transforms the original merchant settings entity to a request DTO, and adds the generated offline token, if it is present.
   * @param {string} id The merchant's ID.
   * @param {MerchantSettings} settings The new object representing the merchant's settings.
   * @param {string} offlineToken The generated offline token.
   * @param {string} password The current user's password.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifySettings$(id: string, settings: MerchantSettings, offlineToken: string, password: string): Observable<void> {
    const requestDto = MerchantSettingsRequest.fromMerchantSettings(settings);
    requestDto.productType = this.contextHolder.productType;
    if (offlineToken) {
      requestDto.offlineToken = offlineToken;
    }
    return this.put$<void>(
      MerchantEndpoints.SETTINGS_TYPE(id, this.contextHolder.productType.toLowerCase()),
      new EntityWithPassword(requestDto, password)
    );
  }

  /**
   * Returns a given merchant's SLA/Fraud check settings.
   * @param {string} id The id of the given merchant.
   * @returns {Observable<SlaFraudSettings>} The result in an Observable.
   */
  public getSlaFraudSettings$(id: string): Observable<SlaFraudSettings> {
    return this.get$<SlaFraudSettings>(MerchantEndpoints.SLA_SETTINGS(id));
  }

  /**
   * Returns an init object for the merchant SLA/Fraud check settings.
   * @returns {Observable<SlaFraudSettingsFields>} The result in an Observable.
   */
  public initSlaFraudSettings$(): Observable<SlaFraudSettingsFields> {
    const fn$ = this.get$<SlaFraudSettingsFields>(MerchantEndpoints.SLA_SETTINGS_INIT);
    return this.cache.cacheFunction$<SlaFraudSettingsFields>(this.SLA_FRAUD_SETTINGS_FIELDS_CACHE_KEY, fn$);
  }

  /**
   * Sends a modification request for the merchant's SLA/Fraud settings.
   * @param {string} id The merchant's ID.
   * @param {SlaFraudSettings} settings The new object representing the SLA/Fraud settings.
   * @param {string} password The current user's password.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifySlaFraudSettings$(id: string, settings: SlaFraudSettings, password: string): Observable<void> {
    return this.put$<void>(MerchantEndpoints.SLA_SETTINGS(id), new EntityWithPassword(settings, password));
  }

  /**
   * Returns a given merchant's multi documents.
   * @param {string} id The id of the given merchant.
   * @returns {Observable<MultiDocumentsResult>} The result in an Observable.
   */
  public getMultiDocuments$(id: string): Observable<MultiDocumentsResult> {
    return this.get$<MultiDocumentsResult>(MerchantEndpoints.MERCHANT_MULTI_DOCUMENTS(id));
  }

  /**
   * Returns an init array for the multi document details page.
   * @returns {Observable<CodeLabel[]>} The result in an Observable.
   */
  public initMultiDocument$(): Observable<CodeLabel[]> {
    return this.get$<{locales: CodeLabel[]}>(MerchantEndpoints.MULTI_DOCUMENTS_INIT).pipe(map(response => response.locales));
  }

  /**
   * Returns a given multi document's details.
   * @param {string} id The id of the merchant.
   * @param {string} documentId The id of the given multi document.
   * @returns {Observable<MultiDocumentDetails>} The result in an Observable.
   */
  public getMultiDocumentDetails$(id: string, documentId: string): Observable<MultiDocumentDetails> {
    return this.get$<MultiDocumentDetails>(this.prefix(id) + MerchantEndpoints.MULTI_DOCUMENT(documentId));
  }

  /**
   * Sends a modification request for the a given multi document.
   * @param {string} id The merchant's ID.
   * @param {MultiDocumentDetails} document The new object representing the updated multi document.
   * @param {string} password The current user's password.
   * @param {string} passCode The current user's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyMultiDocumentDetails$(id: string, document: MultiDocumentDetails, password: string, passCode?: string): Observable<void> {
    return this.put$<void>(
      //@ts-ignore
      this.prefix(id) + MerchantEndpoints.MULTI_DOCUMENT(document.id),
      new EntityWithPassword(document, password, passCode)
    );
  }

  /**
   * Sends an add request for creating a new multi document.
   * @param {string} id The merchant's ID.
   * @param {MultiDocumentDetails} document The new multi document object.
   * @param {string} password The current user's password.
   * @returns {Observable<void>} An empty Observable.
   */
  public addNewMultiDocument$(id: string, document: MultiDocumentDetails, password: string, passCode?: string): Observable<void> {
    return this.post$<void>(this.prefix(id) + MerchantEndpoints.MULTI_DOCUMENTS, new EntityWithPassword(document, password, passCode));
  }

  /**
   * Sends a request to the backend in order to generate an offline token.
   * @param {string} id The merchant's ID.
   * @param {MerchantOfflineTokenSettings} settings The updated settings object.
   * @returns {Observable<string>} The generated offline token as a string.
   */
  public generateOfflineToken$(id: string, settings: MerchantOfflineTokenSettings): Observable<string> {
    //@ts-ignore
    return this.post$<OfflineTokenResult>(MerchantEndpoints.OFFLINE_TOKEN_GENERATE(id), settings).pipe(map(result => result.offlineToken));
  }

  /**
   * Returns the supported multi documents in the MUI app.
   * @returns {Observable<Documents>} The result in an Observable.
   */
  public getSupportedMultiDocuments$(): Observable<Documents> {
    return this.get$<Documents>(MerchantEndpoints.MULTI_DOCUMENTS_SUPPORTED);
  }

  /**
   * Returns the custom multi documents in the MUI app.
   * @returns {Observable<MultiDocument[]>} The result in an Observable.
   */
  public getCustomMultiDocuments$(): Observable<{documents: MultiDocument[]}> {
    return this.get$<{documents: MultiDocument[]}>(MerchantEndpoints.MULTI_DOCUMENTS_CUSTOM);
  }

  /**
   * Returns the Value for the given Generic Key
   * @param {string} id The merchant's ID.
   * @param {string} key The Generic Key
   * @returns {Observable<string>} The result in a string.
   */
  public getValueForGenericKey$(id: string, key: string): Observable<GenericValue> {
    return this.get$<GenericValue>(MerchantEndpoints.GENERIC_VALUE(id, key));
  }

  public getIpWhitelist$(merchantid: string): Observable<MerchantIpRanges> {
    return this.get$<MerchantIpRanges>(MerchantEndpoints.IP_WHITELIST(merchantid));
  }

  public updateIpWhitelist$(merchantid: string, iplist: MerchantIpRanges, password: string, passCode?: string): Observable<void> {
    return this.put$<void>(MerchantEndpoints.IP_WHITELIST(merchantid), new EntityWithPassword(iplist, password, passCode));
  }

  public duplicateAccount$(id: string, newName: string, mail: string, isTenant: boolean, password: any): Observable<void> {
    const payload: AccountDuplicationPayload = {
      password: password,
      entity: {
        merchantName: newName,
        email: mail,
        isTenant
      }
    };

    return this.post$<void>(MerchantEndpoints.DUPLICATE(id), payload);
  }

  public migrateUser$(publicId: string, password: string): Observable<void> {
    const payload = {
      password,
      entity: {
        merchantPublicGuid: publicId
      }
    };

    return this.post$<void>(MerchantEndpoints.MIGRATE, payload);
  }

  public getJumioPlatformUpgradeSettings$(merchantId: string): Observable<JumioPlatformUpgradeSettings> {
    return this.get$<JumioPlatformUpgradeSettings>(MerchantEndpoints.JUMIO_PLATFORM_UPGRADE_SETTINGS(merchantId));
  }

  public updateJumioPlatformUpgradeSettings$(
    merchantId: string,
    jumioPlatformUpgradeSettings: JumioPlatformUpgradeSettings,
    password: string
  ): Observable<void> {
    return this.put$<void>(
      MerchantEndpoints.UPDATE_JUMIO_PLATFORM_UPGRADE_SETTINGS(merchantId),
      new EntityWithPassword(jumioPlatformUpgradeSettings, password)
    );
  }
}

/**
 * A helper class to contain the search query field.
 */
export class MerchantSearchQuery {
  constructor(public query: string) {}
}
