import {APP_BASE_HREF} from '@angular/common';
import {ErrorHandler, NgModule} from '@angular/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JtmComponentsModule, JtmPipesModule} from '@jtm/jtm-components';
import {JtmSecurityContextHolder, JtmServicesModule, JtmServicesPipesModule} from '@jtm/jtm-services';
import {JumioDatadogRumModule, PortalDatadogIdentifier} from '@jumio/datadog-rum';
import {appVersion} from '@jumio/portals.app-version';
import {DatadogAppNames, IPortalConfig, PORTAL_DATA, PortalApiType} from '@jumio/portals.core';
import {FocusFirstDirective, J4LoaderComponent} from '@jumio/portals.core-ui';
import {JumioPortalFeatureFlagModule} from '@jumio/portals.feature-flag';
import {ForceReloadModalComponent, ForceReloadModule, ProvideApiVersionModule} from '@jumio/portals.force-reload';
import {StoreModule} from '@ngrx/store';
import {CoreJ4InterceptorsModule} from 'core/interceptors';
import {J4ErrorHandler, RedirectService} from 'core/services';
import {AlertModule} from 'ngx-bootstrap/alert';
import {PipesModule} from 'public-shared/pipes/pipes.module';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {LoggerService} from 'public-shared/services/logging/logger.service';
import {PasswordModalComponent} from 'shared/components/password-modal/password-modal.component';
import {PasswordOrConfirmModalService} from 'shared/services/password-or-confirm-modal.service';
import {SharedModule} from 'shared/shared.module';
import {environment} from '../environments/environment.prod';
import {WelcomeEnvironmentProvider} from '../environments/welcome-environment-provider';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Auth0CallbackComponent} from './containers/auth0-callback/auth0-callback.component';
import {ErrorComponent} from './containers/error/error.component';
import {Error403Component} from './containers/error403/error403.component';
import {QuickTourViewComponent} from './containers/quick-tour/quick-tour-view/quick-tour-view.component';
import {QuickTourComponent} from './containers/quick-tour/quick-tour.component';

const CONFIG: IPortalConfig = {
  apiType: PortalApiType.CP,
  basePath: '/'
};

@NgModule({
  declarations: [AppComponent, Auth0CallbackComponent, ErrorComponent, Error403Component, QuickTourComponent, QuickTourViewComponent],
  imports: [
    PasswordModalComponent,
    MatDialogModule,
    SharedModule.forRoot(),
    MatMomentDateModule,
    BrowserAnimationsModule,
    J4LoaderComponent,
    ForceReloadModalComponent,
    FocusFirstDirective,
    ForceReloadModule,
    BrowserModule,
    AppRoutingModule,
    JtmComponentsModule,
    JtmPipesModule,
    PipesModule,
    AlertModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    CoreJ4InterceptorsModule,
    JtmServicesModule.forRoot({
      environment,
      tenantName: '',
      skipPortalTokenCheck: false
    }),
    JtmServicesPipesModule,
    JumioDatadogRumModule.forRoot({
      portalIdentifier: PortalDatadogIdentifier.UNIFIED_PORTAL,
      service: DatadogAppNames.UP_WELCOME,
      appVersion: appVersion,
      waitForToken: true
    }),
    ProvideApiVersionModule.forRoot(CONFIG.basePath),
    JumioPortalFeatureFlagModule.forRoot()
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    PasswordOrConfirmModalService,
    {
      provide: APP_BASE_HREF,
      useValue: CONFIG.basePath
    },
    LoggerService,
    JtmSecurityContextHolder,
    {
      provide: ErrorHandler,
      useClass: J4ErrorHandler
    },
    {
      provide: EnvironmentProvider,
      useClass: WelcomeEnvironmentProvider
    },
    RedirectService,
    {
      provide: PORTAL_DATA,
      useValue: CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
